import PaymentForm from "../components/payment/creditcard";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PaymentPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempPrice, setTempPrice] = useState(0);
  const shoppingCart = useSelector((state) => state.shoppingCart.items);
  let totalPrice = 0;

  useEffect(() => {
    console.log(shoppingCart);
    for (let i = 0; i < shoppingCart.length; i++) {
      add(shoppingCart[i].price * shoppingCart[i].quantity);
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const add = (price) => {
    totalPrice += price;
    setTempPrice(totalPrice);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const onSearch = (value) => {
  //   console.log("search:", value);
  //   setClickCount(clickCount + 1);
  //   console.log(`Click count: ${clickCount + 1}`);
  // };

  return (
    <div>
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>Payment </div>
      <br />
      <br />
      <table style={{ border: "1px solid black", textAlign: "center" }}>
        <tr style={{ border: "1px solid black" }}>
          <th style={{ border: "1px solid black" }}>Product Name</th>
          <th style={{ border: "1px solid black" }}>Product Image</th>
          <th style={{ border: "1px solid black" }}>quantity</th>
          <th style={{ border: "1px solid black" }}>Price ($)</th>
          <th style={{ border: "1px solid black" }}>Sub-Price ($)</th>
        </tr>

        {shoppingCart.map((item) => (
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>{item.name}</td>
            <td style={{ border: "1px solid black" }}>
              <img
                width={280}
                alt="logo"
                src={item.image}
                justifyContent="center"
              />
            </td>
            <td style={{ border: "1px solid black" }}>{item.quantity}</td>
            <td style={{ border: "1px solid black" }}>{item.price}</td>
            <td style={{ border: "1px solid black" }}>
              {item.price * item.quantity}
            </td>
          </tr>
        ))}
      </table>
      Total Price $ {tempPrice / 2}
      <Button onClick={showModal}>Confirm</Button>
      <Modal
        title="Payment"
        open={isModalOpen}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        style={{ width: "200%" }}
        onCancel={handleCancel}
      >
        <PaymentForm onOk={handleOk} onCancel={handleCancel} />
      </Modal>
      <br />
      <br />
      <br />
      <br />
      <div
        className="stats shadow"
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="stat">
          <div className="stat-title">Total Page Views</div>
          <div className="stat-value">32</div>
          <div className="stat-desc">21% more than last month</div>
        </div>
      </div>
      <footer className="footer p-10 bg-neutral text-neutral-content">
        <nav>
          <h6 className="footer-title">Services</h6>
          <a className="link link-hover">Branding</a>
          <a className="link link-hover">Design</a>
          <a className="link link-hover">Marketing</a>
          <a className="link link-hover">Advertisement</a>
        </nav>
        <nav>
          <h6 className="footer-title">Company</h6>
          <a className="link link-hover">About us</a>
          <a className="link link-hover">Contact</a>
          <a className="link link-hover">Jobs</a>
          <a className="link link-hover">Press kit</a>
        </nav>
        <nav>
          <h6 className="footer-title">Legal</h6>
          <a className="link link-hover">Terms of use</a>
          <a className="link link-hover">Privacy policy</a>
          <a className="link link-hover">Cookie policy</a>
        </nav>
      </footer>
    </div>
  );
};

export default PaymentPage;
