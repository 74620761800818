import { Button, Tag } from "antd";
import { Avatar, List, Radio, Space, message } from "antd";
import { addItem } from "../components/shoppingCart/shoppingCartSlice";
import { removeItem } from "../components/shoppingCart/shoppingCartSlice";
import { useDispatch } from "react-redux";

export const ProductPage = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  // let dataSource = Array.from({ length: 12 }).map((_, index) => {
  //   return {
  //     id: index,
  //     name: `Product ${index}`,
  //     price: index,
  //     description: "very good",
  //     image: "/001.png",
  //   };
  // });

  let dataSource = [
    {
      id: 0,

      name: "Product 1",

      price: 10,

      description: "This is product 1",

      image: "/001.jpg",
    },

    {
      id: 1,

      name: "Product 2",

      price: 20,

      description: "This is product 2",

      image: "/002.jpg",
    },

    {
      id: 2,

      name: "Product 3",

      price: 30,

      description: "This is product 3",

      image: "/003.jpg",
    },

    {
      id: 3,

      name: "Product 4",

      price: 40,

      description: "This is product 4",

      image: "/004.jpg",
    },

    {
      id: 4,

      name: "Product 5",

      price: 50,

      description: "This is product 5",

      image: "/005.jpg",
    },

    {
      id: 5,

      name: "Product 6",

      price: 60,

      description: "This is product 6",

      image: "/006.jpg",
    },

    {
      id: 6,

      name: "Product 7",

      price: 70,

      description: "This is product 7",

      image: "/007.jpg",
    },

    {
      id: 7,

      name: "Product 8",

      price: 80,

      description: "This is product 8",

      image: "/008.jpg",
    },

    {
      id: 8,

      name: "Product 9",

      price: 90,

      description: "This is product 9",

      image: "/009.jpg",
    },

    {
      id: 9,

      name: "Product 10",

      price: 100,

      description: "This is product 10",

      image: "/010.jpg",
    },

    {
      id: 10,

      name: "Product 11",

      price: 110,

      description: "This is product 11",

      image: "/011.jpg",
    },
  ];

  let clickAdd = (item) => {
    dispatch(addItem(item));

    messageApi.success("Added to cart!");
  };

  let clickRemove = (item) => {
    try {
      dispatch(removeItem(item));
    } catch (e) {
      window.alert("The product is not in cart");
    }
    messageApi.success("Removed from cart!");
  };

  return (
    <div
      style={{
        // backgroundImage: `url(${image})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
        backgroundImage: "url(./page/Capture-19.png)",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#08979c",
      }}
    >
      {contextHolder}
      <br />
      <br />
      <h1 style={{ textAlign: "center" }}>Product Page</h1> <br></br>
      {/* <Table dataSource={dataSource} columns={columns} />; */}
      <List
        className="m-10"
        itemLayout="vertical"
        pagination={{
          position: "both",
          align: "end",
          pageSize: 3,
          showQuickJumper: true,
          defaultCurrent: 1,
        }}
        dataSource={dataSource}
        renderItem={(item, index) => (
          <List.Item
            key={index}
            extra={
              <img
                width={280}
                alt="logo"
                src={item.image}
                justifyContent="center"
              />
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={item.image} />}
              title={item.name}
            />
            <div>
              {item.description}
              <br />${item.price}
            </div>
            <div
              className="flex flex-row gap-4"
              style={{ display: "block", textAlign: "100%" }}
            >
              <Button
                onClick={() => clickAdd(item)}
                type="primary"
                color="white"
                style={{ backgroundColor: "black" }}
              >
                Add to cart
              </Button>

              <Button
                onClick={() => clickRemove(item)}
                color="white"
                style={{ backgroundColor: "#13c2c2" }}
              >
                -
              </Button>
            </div>
            <br />
            <Tag color="green">In Stock</Tag>
            <Tag color="blue">Free Shipping</Tag>
            <Tag color="red">New</Tag>
            <br />
            <br />

            <br />
            <br />
          </List.Item>
        )}
      />
      <footer className="footer p-10 bg-neutral text-neutral-content">
        <nav>
          <h6 className="footer-title">Services</h6>
          <a className="link link-hover">Branding</a>
          <a className="link link-hover">Design</a>
          <a className="link link-hover">Marketing</a>
          <a className="link link-hover">Advertisement</a>
        </nav>
        <nav>
          <h6 className="footer-title">Company</h6>
          <a className="link link-hover">About us</a>
          <a className="link link-hover">Contact</a>
          <a className="link link-hover">Jobs</a>
          <a className="link link-hover">Press kit</a>
        </nav>
        <nav>
          <h6 className="footer-title">Legal</h6>
          <a className="link link-hover">Terms of use</a>
          <a className="link link-hover">Privacy policy</a>
          <a className="link link-hover">Cookie policy</a>
        </nav>
      </footer>
    </div>
  );
};
