import { Menu } from "antd";
import { Link } from "react-router-dom";
const items = [
  {
    key: "home",
    // icon: <MailOutlined />,
    label: (
      <Link to={"/"} style={{ fontSize: "20px" }}>
        Home
      </Link>
    ),
  },
  {
    key: "payment",
    // icon: <MailOutlined />,
    label: (
      <Link to={"/payment"} style={{ fontSize: "20px" }}>
        payment
      </Link>
    ),
  },
];

export const Navbar = () => {
  return (
    <Menu mode="horizontal" items={items} theme="dark" textColor="white" />
  );
};
